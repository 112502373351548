import React from 'react';
import styles from './SolutionsSection.module.scss';
import { Section } from '../Section';
import { SolutionItem } from './SolutionItem';
import { Routes } from '@/core/routes';

export default function SolutionsSection() {
  return (
    <Section className={styles.container}>
      <h1 className={styles.title}>
        What <span>solutions</span> are you looking for?
      </h1>
      <div className={styles.cards}>
        <SolutionItem
          icon="home-05"
          title="Real Estate Agents"
          description="Hyper-Local branded real estate videos"
        />
        <SolutionItem
          icon="currency-dollar-circle"
          title="Mortgage Brokers & Loan officers"
          description="Manage social media with compliance"
          learnMoreHref={Routes.landings.mortgageBrokers()}
        />
        <SolutionItem
          icon="building-07"
          title="Brokerages"
          description="Manage corporate-level social media"
          learnMoreHref={Routes.landings.brokerages()}
        />
      </div>
    </Section>
  );
}
