import { Icon, IconProps } from '@/src/shared/components/Icon';
import { Button } from '@/src/shared/components/Button';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';
import styles from './SolutionItem.module.scss';
import Link from 'next/link';

interface Props {
  icon: IconProps['name'];
  title: string;
  description: string;
  learnMoreHref?: string;
}

export function SolutionItem({
  icon,
  title,
  description,
  learnMoreHref,
}: Props) {
  const isMobile = useIsMobile();
  const content = (
    <div className={styles.card}>
      <div className={styles.wrapper}>
        <Icon name={icon} fontSize={32} className={styles.icon} />
        <div className={styles.content}>
          <h6 className={styles.title}>{title}</h6>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
      {learnMoreHref != null && (
        <Button
          fullWidth
          size={isMobile ? 'xl' : 'lg'}
          color="link"
          endIcon="arrow-right"
          className={styles.cta}
        >
          Learn more
        </Button>
      )}
    </div>
  );
  if (learnMoreHref != null) {
    return (
      <Link style={{ width: '100%' }} href={learnMoreHref} target="_blank">
        {content}
      </Link>
    );
  }
  return <>{content}</>;
}
